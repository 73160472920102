import Login from 'components/login';
import Register from 'components/register';
import { IAppState } from 'models';
import React, { useEffect } from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as adobeTagManager from 'util/adobe-tag-manager';

type AuthProps = IAppState;

const Auth = (props: AuthProps) => {
  // Reset auth type to login when component unmounts
  useEffect(() => {
    return () => {
      props.authFn.setAuthType(constants.AUTH_TYPES.LOGIN);
    };
  }, [props.authFn]);

  useEffect(() => {
    props.globalFn.detectLanguage();
    props.modalFn.onClose(() => adobeTagManager.trackInteraction(adobeTagManager.InteractionType().LOGIN_DISMISS))
  }, [])

  if (props.authProps.authType === constants.AUTH_TYPES.REGISTER) {
    return <Register />;
  }

  return <Login />;
};

export default Connect(Auth);
