import { css } from 'aphrodite/no-important';
import { IButtonData } from 'models';
import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import { style } from './style';

class Button extends React.Component<IButtonData> {
  render() {
    const { copy } = this.props.buttonData;

    const styles = style({
      buttonStyles: this.props.buttonStyles ? this.props.buttonStyles : {},
      options: this.props.options || {},
    });

    return (
      <button
        className={this.props.className + ' '  + css(styles.button)}
        onClick={this.props.onClick}
        aria-label={this.props.ariaLabel}
        aria-disabled={this.props.isDisabled || this.props.isLoading}
        disabled={this.props.isDisabled || this.props.isLoading}
      >
        {this.props.isLoading ? <ClipLoader loading size={20} color={'#fff'} /> : copy}
      </button>
    );
  }
}

export default Button;
