import { AppStateProvider } from 'store/index';
import { ACTION_TYPES } from 'util/constants';
import api from 'util/api';
import * as constants from 'util/constants';
import { useState } from 'react';
import { IAppState, IModalFunctions, IVoteData } from 'models';

export function getContestantVotes(this: AppStateProvider): any {
  const payload = this.state.apiFn.generatePayload(ACTION_TYPES.GET);
  payload.action_type = ACTION_TYPES.GET;

  return api
    .postToVoteApi(payload, this.state.cmsData.settings.version_id)
    .then((response: any) => {
      this.state.voteFn.updateVotes(response.votestring);
    });
}

export function updateVotes(this: AppStateProvider, votestring: any): any {
  const { voteProps } = this.state;
  voteProps.contestantVotes = votestring ? { ...JSON.parse(votestring) } : {};
  voteProps.totalVotes = voteProps.contestantVotes.total || 0;
  delete voteProps.contestantVotes.total;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      voteProps,
    };
  });
}

export async function submitVote(
  this: AppStateProvider,
  voteData: IVoteData
): Promise<IModalFunctions> {
  const { id, voteCount } = voteData;
  const payload = this.state.apiFn.generatePayload(
    constants.ACTION_TYPES.VOTE, { [id]: voteCount, total: voteCount }
  );

  return api
    .postToVoteApi(payload, this.state.cmsData.settings.version_id)
    .then((response: any) => {
      this.state.modalFn.closeModal();
      if (response.response_code === constants.RESPONSE_CODES.GENERAL_INVALID) {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
      } else if (response.response_code === constants.RESPONSE_CODES.VALID) {
        this.state.voteFn.updateVotes(response.votestring);
        this.state.modalFn.openModal(constants.MODAL_TYPES.confirmation);
      } else if (
        response.response_code === constants.RESPONSE_CODES.OVERLIMIT
      ) {
        this.state.voteFn.updateVotes(response.votestring);
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorOverlimit);
      } else if (
        response.response_code === constants.RESPONSE_CODES.OUT_OF_WINDOW
      ) {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorWindow);
      } else {
        this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
      }
    })
    .catch(() => {
      this.state.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
    });
}

export function useVoteAttempt(userData: any) {
  const { isAuthorized, email } = userData;
  const [voteAttempt, setVoteAttempt]: [boolean, any] = useState(false);

  const isUserValid = isAuthorized && !!email;

  return { voteAttempt, setVoteAttempt, isUserValid };
}
