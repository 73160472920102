import { App } from 'components';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Route, Router, Switch } from 'react-router-dom';
import { AppStateProvider } from 'store/index';
import history from 'util/history';
import reportWebVitals from './reportWebVitals';
import './style.scss';
import { OpenAPI } from 'lib/nba-ciam-api';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if( (window as any).env !== 'prod' ) {
  OpenAPI.BASE = 'https://identity-qa.nba.com/qa';
}

root.render(
  <React.StrictMode>
    <Router history={history}>
      <AppStateProvider>
        <Switch>
          <Route exact={true} path='/' component={App} />
          <Route path='/:name' component={App} />
        </Switch>
      </AppStateProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
