import { css } from 'aphrodite/no-important';
import Terms from 'components/terms';
import { ApiError, AuthenticationService } from 'lib/nba-ciam-api';
import { IAppState } from 'models';
import * as React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { style } from './style';
import parse from 'html-react-parser';
import * as adobeTagManager from 'util/adobe-tag-manager';

type LoginProps = IAppState;
class Login extends React.Component<LoginProps> {
  public state = {
    email: '',
    password: '',
    fieldErrors: {} as Record<string, string>,
    hasFbPermissionsError: false,
    isLoading: false,
    formError: null,
  };

  private areButtonsEnabled(): boolean {
    return (
      Object.keys(this.props.termsProps).every((term) => {
        return (
          (this.props.termsProps[term].isChecked && this.props.termsProps[term].isRequired) ||
          !this.props.termsProps[term].isRequired
        );
      }) &&
      this.state.password.length > 0 &&
      this.state.email.length > 0
    );
  }

  public componentDidMount(): void {
    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName().LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc().START, 
      language: this.props.globalProps.language.ietf
    })
  }

  public render(): React.ReactNode {
    const lang = this.props.globalProps.language.code;
    const { copy: copyData, buttons, fields, logo } = this.props.cmsData.text.login;
    const copy = copyData[lang];
    
    const areButtonsEnabled = this.areButtonsEnabled();

    const styles = style({
      globalStyles: this.props.stylesData.global,
      loginStyles: this.props.stylesData.login,
      buttonStyles: this.props.stylesData.login.buttons,
      areButtonsEnabled,
    });

    return (
      <div aria-label='Log in' className={css(styles.login)}>
        <div className={css(styles.logo)}>
          <img src={logo.src} alt={logo.alt_text} />
        </div>

        <h2 className={css(styles.headline)}>{copy.headline}</h2>
        {copy.subheadline && <p className={css(styles.subheadline)}>{copy.subheadline}</p>}

        <form className={css(styles.form)} onSubmit={this._handleEmailLogin}>
          <div className={css(styles.form_group)}>
            <label className={css(styles.form_label)}>{fields.email[lang].label}</label>
            <input
              id='email'
              className={css(styles.form_input, this.state.fieldErrors.email && styles.form_input_invalid)}
              type='email'
              name='email'
              onChange={this._handleInput}
            />
            {this.state.fieldErrors.email && (
              <p className={css(styles.form_error)} role='alert' aria-live='assertive'>
                {this.state.fieldErrors.email}
              </p>
            )}
          </div>

          <div className={css(styles.form_group)}>
            <label className={css(styles.form_label)}>{fields.password[lang].label}</label>
            <input
              id='password'
              className={css(styles.form_input, this.state.fieldErrors.password && styles.form_input_invalid)}
              type='password'
              name='password'
              onChange={this._handleInput}
            />

            {this.state.fieldErrors.password && (
              <p className={css(styles.form_error)} role='alert' aria-live='assertive'>
                {this.state.fieldErrors.password}
              </p>
            )}
          </div>
          <Terms language={lang}/>

          <button
            className={css(styles.button, styles.email_button)}
            disabled={!areButtonsEnabled || this.state.isLoading}
            type='submit'
          >
            {this.state.isLoading ? <ClipLoader loading size={20} color={'#fff'} /> : buttons.submit[lang].copy}
          </button>
          {this.state.formError && <p className={css(styles.form_error)}>{this.state.formError}</p>}
        </form>
        <div>
          <button className={css(styles.not_registered_link)} onClick={this._openRegistration}>
            {parse(copy.not_registered_link)}
          </button>
        </div>
      </div>
    );
  }
  private _openRegistration = () => {
    this.props.authFn.setAuthType(constants.AUTH_TYPES.REGISTER);
  };

  private _handleEmailLogin = async (e: React.SyntheticEvent) => {
    const lang = this.props.globalProps.language.code;
    const { fields } = this.props.cmsData.text.login;
    e.preventDefault();

    if (!this.state.email.match(constants.EMAIL_REGEX)) {
      return this.setState({ fieldErrors: { email: fields.email[lang].error } });
    }

    if (!this.areButtonsEnabled()) {
      return;
    }

    this.setState({ isLoading: true });

    this.trackLoginSubmit();

    try {
      const { email: emailAddress, password } = this.state;
      const email = emailAddress.toLowerCase();

      const response = await AuthenticationService.postAuth('web', {
        email,
        password,
        rememberMe: true,
      });
      const userId = response.data?.user.alternateIds?.NBACIAMGUID!;
      this.props.authFn.login({ email, userId });
      
      this.trackLoginSuccess();
    } catch (err) {
      let defaultError = { message: 'An unknown error occurred', code: 'FORM' };

      const isNbaError = err instanceof ApiError;
      if (isNbaError && err.body.data.message) {
        defaultError.message = err.body.data.message;
      }

      const errors = !isNbaError? defaultError : 
                      err.body.data?.message? { message: err.body.data.message, code: err.body.errorCodes?.[0] }:
                      err.body.data?.details? err.body.data.details : defaultError;
                      
      this.trackLoginError(errors)

      this.setState({ formError: errors?.message || errors?.[0]?.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private trackLoginSubmit() {
    googleHelpers.trackGoogleEvent('login', 'nba-ciam');

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName().LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc().SUBMIT, 
      language: this.props.globalProps.language.ietf
    })
  }

  private trackLoginSuccess() {
    const user = this.props.globalProps.userData;
    const terms = this.props.termsProps;

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName().LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc().SUCCESS, 
      language: this.props.globalProps.language.ietf,
      userId: user.userId,
      usePersonalInfo: terms.marketing.isChecked
    })
  }

  private trackLoginError(error: {message: string, code: string}[] | {message: string, code: string}) {
    const isArray = Array.isArray(error);
    const eventError = isArray? error.map((err) => `${err.code}|${err.message}`) : [`${error.code}|${error.message}`];

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName().LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc().ERROR, 
      eventError,
      language: this.props.globalProps.language.ietf
    })
  }

  private _handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
      fieldErrors: {},
      formError: null,
    });
  };
}

export default Connect(Login);
