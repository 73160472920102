import TagManager from 'react-gtm-module';
import * as constants from 'util/constants';

let isGoogleInitialized = false;

export const initializeGoogle = (gaId: string, gtmContainerId: string) => {
  if (gaId && !isGoogleInitialized) {
    TagManager.initialize({
      gtmId: gtmContainerId,
      dataLayer: {
        project_ga_id: gaId
      },
    });
    isGoogleInitialized = true;
  }
};

export const trackGoogleEvent = (event: string, method: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      method: method,
    }
  });
}

export const trackGooglePage = (page: string) => {
  if (isGoogleInitialized) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'virtual_page_view',
        pageUrl: window.location.href,
        pageTitle: page
      }
    });
  }
};
