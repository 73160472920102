import { css } from 'aphrodite/no-important';
import { AuthenticationService } from 'lib/nba-ciam-api';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import { checkIfTrue } from 'util/helpers';
import { style } from './style';

class User extends React.Component<IAppState> {
  public state = {
    triggeredLogin: false,
  };

  public componentDidUpdate(prevProps: any): void {
    const windowStatus = checkIfTrue(this.props.cmsData.settings.window_status);
    if (this.state.triggeredLogin && this.props.globalProps.userData.isAuthorized) {
      this.props.modalFn.closeModal();
    }

    if (this.state.triggeredLogin && !this.props.modalProps.type) {
      this.setState({ triggeredLogin: false });
    }

    if (this.props.globalProps.userData.isAuthorized && !windowStatus) {
      this._handleLogoff();
    }
  }

  public render(): React.ReactNode {
    const { text, settings } = this.props.cmsData;
    const { copy } = text.user;
    const { email, isAuthorized, name } = this.props.globalProps.userData;
    const windowStatus = checkIfTrue(settings.window_status);

    const styles = style({
      globalStyles: this.props.stylesData.global,
      userStyles: this.props.stylesData.user,
    });

    let content = (
      <button className={css(styles.button)} onClick={this._handleLogon}>
        {copy.login_cta}
      </button>
    );

    if (!windowStatus) {
      content = <div />;
    } else if (isAuthorized) {
      content = (
        <div>
          <span className={css(styles.greeting_message)}>
            {copy.greeting_message} {name ? name : email}
          </span>
          <button className={'User__logout-btn ' + css(styles.button)} onClick={this._handleLogoff}>
            {copy.logout_cta}
          </button>
        </div>
      );
    }

    return <div className={css(styles.user)}>{content}</div>;
  }

  private _handleLogoff = async () => {
    this.props.authFn.logout();

    try {
      await AuthenticationService.postLogout();
    } catch(err) {
      console.log('Failed to logout user with NBA')
    }
  };

  private _handleLogon = () => {
    this.props.modalFn.openModal(constants.MODAL_TYPES.auth);
    this.setState({ triggeredLogin: true });
  };
}

export default Connect(User);
