import * as React from 'react';

export const CloseIcon = () => {
  return (
    <span className='close-icon'>
      <svg width='16.97' height='16.97' viewBox='0 0 16.97 16.97'>
        <path
          d='M12.5,9.67L18.16,4,21,6.84,15.33,12.5,21,18.16,18.16,21,12.5,15.33,6.84,21,4,18.16,9.67,12.5,4,6.84,6.84,4Z'
          transform='translate(-4.01 -4.01)'
        />
      </svg>
    </span>
  );
};

export const RightArrowIcon = () => {
  return (
    <span className='right-arrow-icon'>
      <svg width='28.99' height='49.5' viewBox='0 0 28.99 49.5'>
        <path
          d='M23.85,27.92L3.34,7.41,7.58,3.17,28.09,23.67l4.24,4.24L7.58,52.66,3.34,48.42Z'
          transform='translate(-3.34 -3.17)'
        />
      </svg>
    </span>
  );
};

export const LeftArrowIcon = () => {
  return (
    <span className='left-arrow-icon'>
      <svg width='28.99' height='49.5' viewBox='0 0 28.99 49.5'>
        <path
          d='M11.83,27.92L32.33,48.42l-4.24,4.24L7.58,32.16,3.34,27.92,28.09,3.17l4.24,4.24Z'
          transform='translate(-3.34 -3.17)'
        />
      </svg>
    </span>
  );
};

// Social
export const FacebookIcon = () => {
  return (
    <span className='facebook-icon'>
      <svg width='22.7' height='48.85' viewBox='0 0 22.7 48.85'>
        <path
          d='M36.35,16.39H28.62V11.32A2.06,2.06,0,0,1,30.77,9h5.45V0.61l-7.51,0c-8.34,0-10.24,6.24-10.24,10.24v5.58H13.65V25h4.82v24.4H28.62V25h6.85Z'
          transform='translate(-13.65 -0.58)'
        />
      </svg>
    </span>
  );
};

export const TwitterIcon = () => {
  return (
    <span className='twitter-icon'>
      <svg width='24' height='38.46' viewBox='0 -100 1200 1400'>
      <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
      </svg>
    </span>
  );
};

export const PlusIcon = () => {
  return (
    <span className='plus-icon'>
      <svg width='17.98' height='17.76' viewBox='0 0 17.98 17.76'>
        <polygon points='6.93 17.76 6.93 10.87 0 10.87 0 6.86 6.93 6.86 6.93 0 11.05 0 11.05 6.86 17.98 6.86 17.98 10.87 11.05 10.87 11.05 17.76 6.93 17.76' />
      </svg>
    </span>
  );
};

export const MinusIcon = () => {
  return (
    <span className='minus-icon'>
      <svg width='18' height='4' viewBox='0 0 18 4'>
        <polygon points='0 4 0 0 18 0 18 4 0 4' />
      </svg>
    </span>
  );
};
