import { css } from 'aphrodite/no-important';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { FacebookIcon, TwitterIcon } from 'util/icons';
import { style } from './style';
import * as adobeTagManager from 'util/adobe-tag-manager';

class Share extends React.Component<IAppState> {
  public render(): React.ReactNode {
    const { copy, settings } = this.props.cmsData.text.share;
    const { contestantIndex } = this.props.globalProps;
    const contestant = this.props.globalProps.contestants[contestantIndex];
    const styles = style({
      shareStyles: this.props.stylesData.thankYou.share,
    });

    const tweet = copy.tweet
      .replace(/{{NAME}}/gi, contestant.name.trim())
      .replace(/{{HASHTAG}}/gi, contestant.hashtag.trim());

    const trackAdobeShareEvent = () => {
      adobeTagManager.trackInteraction(adobeTagManager.InteractionType().SHARE, {
        playerName: contestant.name,
        playerID: contestant.player_id,
        teamName: contestant.team_name,
        teamID: contestant.team_id,
      })
    }

    const twitterShare = () => {
      helpers.twitterShare(tweet, constants.GA_EVENTS.TWITTER_SHARE)
      trackAdobeShareEvent();
    }

    const facebookShare = () => {
      const shareURL = `${window.location.href}${constants.FACEBOOK_SHARE_PATH}`;
      const u = encodeURIComponent(shareURL);
  
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${u}`, 'popup', 'width=600, height=400, scrollbars=no');
  
      googleHelpers.trackGoogleEvent('share', 'facebook');
      trackAdobeShareEvent();
    };

    return (
      <div className={css(styles.share)}>
        {(helpers.checkIfTrue(settings.display_twitter) || helpers.checkIfTrue(settings.display_facebook)) && (
          <h3 className={css(styles.cta)}>{copy.cta}</h3>
        )}

        {helpers.checkIfTrue(settings.display_twitter) && (
          <button
            className={css(styles.social_button, styles.social_twitter)}
            onClick={twitterShare}
            aria-label='Share on Twitter'
          >
            <TwitterIcon />
          </button>
        )}

        {helpers.checkIfTrue(settings.display_facebook) && (
          <button
            className={css(styles.social_button, styles.social_facebook)}
            onClick={facebookShare}
            aria-label='Share on Facebook'
          >
            <FacebookIcon />
          </button>
        )}
      </div>
    );
  }
}

export default Connect(Share);
