import { css } from 'aphrodite/no-important';
import AdUnit from 'components/ad-unit/index';
import Auth from 'components/auth/index';
import Closed from 'components/closed/index';
import Countdown from 'components/countdown/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Grid from 'components/grid/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Modal from 'components/modal/index';
import Panels from 'components/panels/index';
import Share from 'components/share/index';
import Confirmation from 'components/thanks/index';
import User from 'components/user/index';
import Vote from 'components/vote/index';
import { IAppState, ICmsData } from 'models';
import * as React from 'react';
import { Connect } from 'store/index';
import api from 'util/api';
import * as cmsHelpers from 'util/cms-helpers';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { checkIfTrue, getDeviceData, insertFonts, normalizeForUrl } from 'util/helpers';
import history from 'util/history';
import { style } from './style';
import { ProfileService } from 'lib/nba-ciam-api';
import { setCampaignId } from 'util/adobe-tag-manager';

class App extends React.Component<IAppState> {
  public timeoutCms: number | undefined;
  public timeoutStyles: number | undefined;

  public componentDidMount(): void {
    api
      .fetchCms()
      .then((cmsData: ICmsData | null) => {
        if (cmsData) {
          setCampaignId(cmsData?.text?.snapshot_settings.adobe_campaign_id);
          this.props.cmsFn.storeCmsData(cmsData);
          return cmsHelpers.checkGeo(cmsData);
        }
        return undefined;
      })
      .then(this.props.cmsFn.handleGeoData)
      .then(() => {
        if (this.props.cmsData.text) {
          return api.fetchStyles(this.props.cmsData.text.snapshot_settings.styles_widget_id);
        }
        return undefined;
      })
      .then((stylesData: ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        const device = getDeviceData();
        this.props.globalFn.setDevice(device);
        this._checkProfile();
      })
      .then(() => {
        if (this.props.match.params.name) {
          this._displayContestant();
        }
      });
  }

  public componentDidUpdate(prevProps: IAppState): void {
    if (
      this.props.cmsProps.isAppReady &&
      this.props.cmsData.text &&
      this.props.cmsProps.inRegion &&
      !prevProps.cmsProps.isAppReady
    ) {
      const { google_analytics, google_tag_manager } = this.props.cmsData.settings;
      googleHelpers.initializeGoogle(google_analytics, google_tag_manager);

      insertFonts(this.props.stylesData.global.fontImports.filter((url: string) => !!url));
      this._pollCms();
      this._pollStyles();
    }
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timeoutCms);
    clearTimeout(this.timeoutStyles);
  }

  public render(): React.ReactNode {
    if (!this.props.cmsProps.isAppReady || !this.props.cmsData.text) {
      return <Loading />;
    }

    const { text } = this.props.cmsData;
    const { stylesData } = this.props;

    const styles = style({
      pageStyles: this.props.stylesData.app,
      globalStyles: this.props.stylesData.global,
      modalOpen: this.props.modalProps.type !== '',
    });

    const modalMap = {
      confirmation: (
        <Confirmation>
          <Share />
        </Confirmation>
      ),
      errorGeneric: <ErrorMessage data={text.errors.generic} styles={stylesData.errors.generic} />,
      errorOverlimit: <ErrorMessage data={text.errors.overlimit} styles={stylesData.errors.overlimit} />,
      errorWindow: <ErrorMessage data={text.errors.window} styles={stylesData.errors.window} />,
      auth: <Auth />,
      vote: <Vote SwapLogin={Auth} />,
    };

    return (
      <div className={css(styles.page)}>
        <div className={css(styles.app_container)} aria-hidden={this.props.modalProps.type !== ''}>
          {checkIfTrue(text.ads.leaderboard.settings.display) && <AdUnit size={constants.AD_UNITS.LEADERBOARD} />}

          {checkIfTrue(text.ads.mobile_leaderboard.settings.display) && (
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />
          )}

          {checkIfTrue(text.header.settings.display) && (
            <Header>
              {checkIfTrue(text.ads.rectangle.settings.display) && <AdUnit size={constants.AD_UNITS.RECTANGLE} />}
              {checkIfTrue(text.ads.mobile_rectangle.settings.display) && (
                <AdUnit size={constants.AD_UNITS.MOBILE_RECTANGLE} />
              )}
            </Header>
          )}

          {this.renderContent()}

          {checkIfTrue(text.footer.settings.display) && <Footer />}
        </div>

        {modalMap[this.props.modalProps.type] && (
          <Modal>
            {modalMap[this.props.modalProps.type]}
            {checkIfTrue(text.ads.modal.settings.display) && this.props.modalProps.type !== 'login' && (
              <AdUnit size={constants.AD_UNITS.MODAL} />
            )}
          </Modal>
        )}
      </div>
    );
  }

  renderContent = () => {
    const { text, settings } = this.props.cmsData;
    const { stylesData } = this.props;

    const styles = style({
      globalStyles: stylesData.global,
    });

    if (!this.props.cmsProps.inRegion) {
      return <ErrorMessage name={constants.PAGES.GEO} data={text.errors.geo} styles={stylesData.errors.geo} />;
    }

    return (
      <div className={css(styles.app)} role='main'>
        <User />

        {!checkIfTrue(settings.window_status) && (
          <Closed>{checkIfTrue(text.closed.settings.display_countdown) && <Countdown key='countdown' />}</Closed>
        )}

        {(checkIfTrue(settings.window_status) || checkIfTrue(text.closed.settings.display_grid)) && (
          <Grid>
            <Panels />
          </Grid>
        )}

        {checkIfTrue(text.ads.leaderboard_bottom.settings.display) && (
          <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} />
        )}

        {checkIfTrue(text.ads.mobile_leaderboard_bottom.settings.display) && (
          <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} />
        )}
      </div>
    );
  };

  _displayContestant = () => {
    const name = this.props.match.params.name.toLowerCase();

    let index = -1;
    this.props.globalProps.contestants.some((contestant: any, i: number) => {
      const contestantName = normalizeForUrl(contestant.name).toLowerCase();
      if (name === contestantName) {
        index = i;
        return true;
      }
      return false;
    });

    if (index === -1) {
      return history.push('/');
    }

    this.props.globalFn.setTargetIndex(index);
    this.props.modalFn.openModal(constants.MODAL_TYPES.vote);
  };

  _pollCms() {
    api
      .fetchCms()
      .then((cmsData: ICmsData | null) => {
        if (cmsData) {
          setCampaignId(cmsData?.text?.snapshot_settings.adobe_campaign_id);
          this.props.cmsFn.storeCmsData(cmsData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;
        if (
          this.props.cmsData &&
          this.props.cmsData.text.snapshot_settings.polling_rate >= constants.MIN_POLLING_RATE
        ) {
          pollingRate = this.props.cmsData.text.snapshot_settings.polling_rate;
        }
        this.timeoutCms = window.setTimeout(this._pollCms.bind(this), pollingRate);
      });
  }

  _pollStyles() {
    api
      .fetchStyles(this.props.cmsData.text.snapshot_settings.styles_widget_id)
      .then((stylesData: ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;
        if (this.props.cmsData && this.props.stylesData.snapshotSettings.pollingRate >= constants.MIN_POLLING_RATE) {
          pollingRate = this.props.stylesData.snapshotSettings.pollingRate;
        }
        this.timeoutStyles = window.setTimeout(this._pollStyles.bind(this), pollingRate);
      });
  }

  _checkProfile = async () => {
    try {
      const response = await ProfileService.getProfile('web', 'email,alternateIds');

      const userId = response.data?.result?.alternateIds?.NBACIAMGUID!;
      const email = response.data?.result?.email;

      if( userId && email ) {
        this.props.authFn.login({ email, userId });
        return;
      }
    } catch(e) {
      // Do nothing if profile returns
    }
  };
}

export default Connect(App);
