import { css } from 'aphrodite/no-important';
import { IErrorMessageData, IAppState } from 'models';
import * as React from 'react';
import { Connect } from 'store/index';
import * as googleHelpers from 'util/google-helpers';
import * as adobeTagManager from 'util/adobe-tag-manager';
import * as constants from 'util/constants';
import { style } from './style';


type Props = IErrorMessageData & IAppState;

class ErrorMessage extends React.Component<Props> {
  componentDidMount() {
    if (this.props.name === constants.PAGES.GEO) {
      adobeTagManager.pageView(adobeTagManager.PageName().GEO, {
        userID: this.props.globalProps.userData.userId
      });

      googleHelpers.trackGooglePage(constants.GA_PAGES.GEO);
    }
  }

  render() {
    const { copy } = this.props.data;
    const styles = style({
      errorStyles: this.props.styles,
      globalStyles: this.props.stylesData.global,
    });

    return (
      <div className={css(styles.error)} aria-live='assertive'>
        <h1 className={css(styles.headline)}>{copy.headline}</h1>
        <p className={css(styles.description)}>{copy.description}</p>

        {this.props.data.link && this.props.data.link.url && (
          <a
            href={this.props.data.link.url}
            className={css(styles.link)}
            target='_blank'
          >
            {' '}
            {this.props.data.link.copy}{' '}
          </a>
        )}
      </div>
    );
  }
}

export default Connect(ErrorMessage);
