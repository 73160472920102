import { isWebview } from '../util';

let campaignId = 'all-star-mvp-vote';

export const setCampaignId = (id: string) => {
  campaignId = id;
};

export const PageName = () => {
  return {
    VOTE: `nba:${campaignId}:main`,
    CLOSED: `nba:${campaignId}:closed`,
    GEO: `nba:${campaignId}:not-available`
  }
};

export const pageView = (name: string, {language, userID = ""} : {language?: string, userID?: string | undefined}) => {
  window.digitalData = {
    page: {
      pageInfo: {
        pageName: name,
        siteSection: `nba:${campaignId}`
      },
    },
    site: {
      league: "nba",
      partner: "telescope",
      propertyName: isWebview()? "nba:app": "nba:web", // "nba:web"; or nba:app if presented in webview
    },
    user: {
      ...(language && {language}),
      userState: userID? "authenticated" : "guest",
      // AUTHENTICATION & AUTHORIZATION
      userID: userID, // NBA CIAM GUID, if authenticated
    },
  };
}

export const ModalName = () => {
  return {
    VOTE: `nba:${campaignId}:player`,
    THANKS: `nba:${campaignId}:vote-confirmation`,
  }
};

export const modalView = (name: string, content: {playerName: string, playerID: string, teamName: string, teamID: string}) => {
  const {playerName, playerID, teamName, teamID} = content;

  let modalDetail = {
    page: {
      pageInfo: {
        pageName: name,
      },
      content: {
        playerName, // proper-case player name
        playerID, // player ID number
        teamName, // team tricode
        teamID // team ID number
      }
    }
  }

  window._satellite && window._satellite.track("track-modal", modalDetail);
}

export const InteractionType = () => {
  return {
    VOTE: `nba:${campaignId}:player:vote`,
    VOTE_DISMISS: `nba:${campaignId}:player:dismiss`,
    LOGIN_DISMISS: `nba:${campaignId}:login:dismiss`,
    SHARE: `nba:${campaignId}:vote-confirmation:share`,
    THANKS_DISMISS: `nba:${campaignId}:vote-confirmation:dismiss`,
  }
};

export const trackInteraction = (type: string, content?: {playerName: string, playerID: string, teamName: string, teamID: string}) => {
  const {playerName, playerID, teamName, teamID} = content || {};
  
  let interactionDetail = {
    interactionId: type,
    ...(playerName && {playerName}), // proper-case player name
    ...(playerID && {playerID}),  // player ID number
    ...(teamName && {teamName}), // team tricode
    ...(teamID && {teamID}), // team ID number
  }

  window._satellite && window._satellite.track("track-click", interactionDetail);
}

export const CiamEventName = () => {
  return {
    LOGIN: 'login',
    REGISTRATION: 'registration'
  }
}

export const CiamEventDesc = () => {
  return {
    START: 'start',
    SUBMIT: 'submit',
    SUCCESS: 'success',
    ERROR: 'error'
  }
}

type CiamEvent = {
  eventName: string,
  eventDesc: string,
  eventError?: string[], // "<error field 1>|<error message 1>"
  language: string,
  userId?: string,
  usePersonalInfo?: boolean
}

export const trackCiamEvent = ({ eventName, eventDesc, eventError = [], language, userId, usePersonalInfo } : CiamEvent) => {
  let ciamEventDetail = {
    eventName,
    eventDesc,
    eventError, // comma-separated list of errors; each error gives a field or type
      // followed by a pipe and then a specific error description
    detailInfo: {
      language,
      ...(userId && {userId}), // Only upon registration success & login success events
      userState: userId? "authenticated" : "guest", // must be "authenticated" upon success
      ...(usePersonalInfo && {usePersonalInfo}) // if user opts in to marketing communications
    }
   }

  window._satellite && window._satellite.track("ciamEvent", ciamEventDetail);
}