/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlternateId } from '../models/AlternateId';
import type { BillingAddress } from '../models/BillingAddress';
import type { Client } from '../models/Client';
import type { DeviceId } from '../models/DeviceId';
import type { EmailProperties } from '../models/EmailProperties';
import type { EmailSubscriptionPreference } from '../models/EmailSubscriptionPreference';
import type { ExtendedNameAttributes } from '../models/ExtendedNameAttributes';
import type { ExtendedProfile } from '../models/ExtendedProfile';
import type { FavoritePlayer } from '../models/FavoritePlayer';
import type { FavoriteTeam } from '../models/FavoriteTeam';
import type { Governance } from '../models/Governance';
import type { PersonalDetails } from '../models/PersonalDetails';
import type { PhoneDetails } from '../models/PhoneDetails';
import type { Photos } from '../models/Photos';
import type { Profile } from '../models/Profile';
import type { Profiles } from '../models/Profiles';
import type { PushNotificationPreference } from '../models/PushNotificationPreference';
import type { SourceDetails } from '../models/SourceDetails';
import type { VerifyResponse } from '../models/VerifyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {

    /**
     * Update a user's profile information
     * Fields passed in the body of the request will replace what is saved in the profile. If, for example, a single item needs to be removed from an array property then send the original array without the item. Fields not sent in the payload will be ignored and left unchanged in the profile.
     * @param xClientPlatform This header determines whether the JWT and refresh token will be sent back in the response payload or a cookie. A value of 'web' will result in the tokens being placed in a cookie. All other values will return the tokens in the response payload.
     * @param requestBody
     * @returns Profile 200 OK
     * @throws ApiError
     */
    public static putProfile(
        xClientPlatform: string,
        requestBody: {
            givenName?: string;
            familyName?: string;
            alternateIds?: AlternateId;
            billingAddress?: BillingAddress;
            clients?: Array<Client>;
            deviceIds?: Array<DeviceId>;
            displayName?: string;
            emailProperties?: EmailProperties;
            emailSubscriptionPreferences?: Array<EmailSubscriptionPreference>;
            emailVerified?: string;
            extendedNameAttributes?: ExtendedNameAttributes;
            extendedProfile?: Array<ExtendedProfile>;
            favoritePlayers?: Array<FavoritePlayer>;
            favoriteTeams?: Array<FavoriteTeam>;
            governance?: Governance;
            legacyModifiedDate?: string;
            personalDetails?: PersonalDetails;
            photos?: Array<Photos>;
            primaryPhone?: PhoneDetails;
            profiles?: Array<Profiles>;
            pushNotificationPreferences?: Array<PushNotificationPreference>;
            secondaryPhone?: PhoneDetails;
            sourceDetails?: SourceDetails;
            vipType?: string;
        },
    ): CancelablePromise<Profile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/profile',
            headers: {
                'X-Client-Platform': xClientPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * Fetch all user's profile information, or fetch specific information via query parameters.
     * @param xClientPlatform This header determines whether the JWT and refresh token will be sent back in the response payload or a cookie. A value of 'web' will result in the tokens being placed in a cookie. All other values will return the tokens in the response payload.
     * @param fields Value used to query specific profile data, example 'email,givenName,familyName' returns only those 3 values
     * @param entitlements Value used to request the users evergent subscriptions, should be 'true', 'false', or defaults to false already.
     * @param opinPartners Value used to request the users opin partner data, should be 'true', 'false', or defaults to false already.
     * @returns Profile 200 OK
     * @throws ApiError
     */
    public static getProfile(
        xClientPlatform: string,
        fields?: string,
        entitlements?: string,
        opinPartners?: string,
    ): CancelablePromise<Profile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile',
            headers: {
                'X-Client-Platform': xClientPlatform,
            },
            query: {
                'fields': fields,
                'entitlements': entitlements,
                'opinPartners': opinPartners,
            },
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * @returns VerifyResponse 200 OK
     * @throws ApiError
     */
    public static getProfileJwt(): CancelablePromise<VerifyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile/jwt',
            errors: {
                401: `Unauthorized`,
                500: `500 Internal Server Error`,
            },
        });
    }

}
